export const SortCategories = Object.freeze({
    ACTIVITY: 'activity',
    SURGEON: 'surgeon_name',
    PATIENT: 'patient_name',
    PATIENT_REF: 'ocos_patient_ref',
});

// SortCategoryTableMap is used to map the sort category from the table to the API
// SortCategoryTableMapReverse is used to map the sort category from the API to the value that the table uses
export const SortCategoryTableMap = Object.freeze({
    updated: 'activity',
    name: 'patient_name',
    doctor_full_name: 'surgeon_name',
});

export const SortCategoryTableMapReverse = Object.freeze({
    activity: 'updated',
    patient_name: 'name',
    surgeon_name: 'doctor_full_name',
});

export const Genders = Object.freeze({
    MALE: 'M',
    FEMALE: 'F',
    OTHER: 'O',
});
